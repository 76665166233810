import { defaults } from '../../index.js';
import { gsapConfig } from '../../index.js';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";

window.addEventListener("DOMContentLoaded", () => {
 gsap.registerPlugin(ScrollTrigger);
 gsapConfig();
 animateTimeline();
});

function animateTimeline() {
 const timeline = document.querySelectorAll(".timeline");
 const pricingHeading = document.querySelectorAll(".pricing-heading");
 let tl = gsap.timeline({
  scrollTrigger: {
   trigger: pricingHeading,
   start: "bottom bottom",
  }
 });
 tl.fromTo(timeline, { x: "-100%" }, { x: 0, duration: defaults.duration * 3 });
 return;
}
